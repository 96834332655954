const config = {
  siteName: 'Skinwallet',
  siteTitle:
    'Sell CS:GO Skins for Real Money & Withdraw to the e-wallet of your choice | Skinwallet',
  siteUrl: 'https://sw.itsua.eu',
  siteDescription:
    'Sell your CSGO skins fast and cash out instantly! Log in with your Steam, evaluate your inventory, sell skins and pay out money in 5 minutes.',
  siteLanguages: ['pl', 'ru', 'tr', 'de', 'fr', 'pt', 'br', 'se'],
  siteDefaultLanguage: 'en',
  siteLocaleDictionary: {
    en: 'en-US',
    pl: 'pl-PL',
    ru: 'ru-RU',
    tr: 'tr-TR',
    de: 'de-DE',
    fr: 'fr-FR',
    pt: 'pt-PT',
    br: 'pt-BR',
    se: 'sv-SE',
  },
  siteLanguageDictionary: {
    en: 'en',
    pl: 'pl',
    ru: 'ru',
    tr: 'tr',
    de: 'de',
    fr: 'fr',
    pt: 'pt-PT',
    br: 'pt-BR',
    se: 'sv',
  },
  siteOgImage: '/socialmedia-og-new.jpg',
  siteTwImage: '/socialmedia-tw-new.jpg',
  twitterUsername: '@SkinWalletcom',
  gtmID: 'GTM-W8SQLMZ',
  themeColor: '#00b22d',
  backgroundColor: '#222221',
};

module.exports = config;
