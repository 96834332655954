/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import {
  siteLocaleDictionary,
  siteLanguageDictionary,
  siteUrl,
  siteDefaultLanguage,
  siteLanguages,
} from '../../../config';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteName
        defaultTitle: siteTitle
        defaultDescription: siteDescription
        defaultLang
        defaultOgImage: siteOgImage
        defaultTwImage: siteTwImage
        twitterUsername
      }
    }
  }
`;

export const createAlternateLinks = (
  originalPath,
  languages = siteLanguages,
) => {
  const languagesLinks = languages.map((language) => ({
    rel: 'alternate',
    hrefLang: siteLanguageDictionary[language],
    href: `${siteUrl}/${language}${originalPath}`,
  }));

  return [
    {
      rel: 'alternate',
      href: `${siteUrl}${originalPath}`,
      hreflang: 'x-default',
    },
    {
      rel: 'alternate',
      href: `${siteUrl}${originalPath}`,
      hreflang: siteDefaultLanguage,
    },
    ...languagesLinks,
  ];
};

function SEO({
  description,
  lang,
  title,
  thumbnailOg,
  thumbnailTw,
  pathname,
  robots,
  meta,
  link,
  script,
}) {
  const {
    site: {
      siteMetadata: {
        siteName,
        defaultTitle,
        defaultDescription,
        defaultLang,
        defaultOgImage,
        defaultTwImage,
        twitterUsername,
      },
    },
  } = useStaticQuery(query);

  const pageTitle = title ? `${title} | ${siteName} ` : `${defaultTitle}`;
  const metaDescription = description || defaultDescription;
  const metaOgImage = `${siteUrl}${thumbnailOg || defaultOgImage}`;
  const metaTwImage = `${siteUrl}${thumbnailTw || defaultTwImage}`;
  const metaLang = lang || defaultLang;
  const metaUrl = `${siteUrl}${pathname}`;

  const metaTags = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:site_name',
      content: siteName,
    },
    {
      property: 'og:locale',
      content: siteLocaleDictionary[metaLang],
    },
    {
      property: 'og:url',
      content: metaUrl,
    },
    {
      property: 'og:image',
      content: metaOgImage,
    },
    {
      property: 'og:title',
      content: pageTitle,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:site',
      content: twitterUsername,
    },
    {
      name: 'twitter:title',
      content: pageTitle,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
    {
      name: 'twitter:image',
      content: metaTwImage,
    },
    {
      name: 'robots',
      content: robots || 'noindex, nofollow',
    },
    // https://www.mywot.com/ verification key
    { name: 'wot-verification', content: 'c08ad1431f4f4ce4aa3c' },
  ].concat(meta);
  const linkTags = [
    {
      rel: 'canonical',
      href: metaUrl,
    },
    {
      rel: 'dns-prefetch',
      href: '//in.hotjar.com',
    },
    {
      rel: 'dns-prefetch',
      href: '//script.hotjar.com',
    },
    {
      rel: 'dns-prefetch',
      href: '//vars.hotjar.com',
    },
    {
      rel: 'dns-prefetch',
      href: '//www.googletagmanager.com',
    },
    {
      rel: 'dns-prefetch',
      href: '//www.google-analytics.com',
    },
    {
      rel: 'dns-prefetch',
      href: '//connect.facebook.net',
    },
  ].concat(link);

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLang,
      }}
      title={pageTitle}
      meta={metaTags}
      link={linkTags}
      script={script}
    />
  );
}

SEO.defaultProps = {
  lang: '',
  meta: [],
  thumbnailOg: '',
  thumbnailTw: '',
  pathname: '/',
  description: '',
  robots: '',
  link: [],
  script: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  thumbnailOg: PropTypes.string,
  thumbnailTw: PropTypes.string,
  pathname: PropTypes.string,
  robots: PropTypes.string,
  link: PropTypes.arrayOf(PropTypes.object),
  script: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
